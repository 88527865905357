/** file di customizzazione sidea (nota: i file di stile dei singoli componenti hanno priorità)  */
/* stylelint-disable declaration-no-important */

/* Variabili globali */

:root {
    --newsletter-icon: url('images/ico-mail.png');
    --icon-map: url('images/ico-map.png');
    --arrow-next: url('images/arrow-next.svg');
    --arrow-prev: url('images/arrow-prev.svg');
    --arrow-down: url('images/arrow-down.svg');
    --icon-arrow-circle: url('images/icon-arrow-circle.svg');
    --loghi-secure: url('images/loghi-ecomm.png');
    --icon-check: url('images/icon-check.svg');
    --ico-sped: url('images/ico-sped.svg');
    --ico-shopper: url('images/ico-shopper.svg');
    --icon-mail: url('images/ico-mail.svg');
    --icon-phone: url('images/ico-phone.svg');
    --icon-phone-white: url('images/ico-phone-white.svg');
    --icon-check-awsome: url('images/awesome-check.svg');
    --icon-arrow-right: url('images/arrow-right.png');
    --icon-telefono: url('images/telefono.png');
    --icon-mondo: url('images/mondo.png');
    --ionic-search: url('images/ionic-md-locate.png');
    --stampante: url('images/stampante.svg');
    --stampante2: url('images/stampante2.svg');
    --facebook-icon: url('images/facebook.png');
    --google-icon: url('images/google.png');
    --twitter-icon: url('images/twitter.png');
    --linkedin-icon: url('images/linkedin.png');
    --small-phone: url('images/small-phone.svg');
    --small-message: url('images/small-message.svg');
}

/* generali */

html {
    scroll-behavior: smooth;
}

body iframe:last-of-type {
    display: none;
}

body {
    overflow-x: hidden;
    margin-block-start: 0 !important;
    
    main.CmsPage {
        iframe {
            display: block !important;
        }
    }

    &.noscroll {
        overflow: hidden;
    }
}

input {
    caret-color: #222 !important;
}

.only-mobile-visible {
    @include desktop {
        display: none !important;
    }

    @include tablet {
        display: none !important;
    }
}

.only-desktop-visible {
    @include mobile {
        display: none !important;
    }
}

.black-bg {
    background-color: $black;
    color: $white;
}

.darkgrey-bg {
    background-color: $default-neutral-base-color;
    color: $white;
}

.white-bg {
    background-color: #ffffff;
    color: $black;
}

.black-bg,
.darkgrey-bg {
    .Button-Text,
    .Button-Text .pagebuilder-button-primary, 
    .Button-Text .pagebuilder-button-secondary {
        color: $default-primary-base-color;

        &::before {
            background-color: $default-primary-base-color;
        }

        &:hover {
            color: $default-primary-light-color;
    
            &::before {
                background-color: $default-primary-light-color;
            }
        }
    }

    &.product-showcase .pagebuilder-button-link,
    .pagebuilder-button-link {
        color: $default-primary-base-color;
        border-block-end: 1px solid $default-primary-base-color;

        &:hover {
            color: $default-primary-light-color;
            border-block-end: 1px solid $default-primary-light-color;
        }
    }

    .ProductCard {
        &-Name,
        &-Brand,
        .Product-Brand,
        .LinkBrand,
        .ProductAttributeValue,
        .ProductPrice-Price,
        .ProductPrice-PriceValue,
        .ProductPrice-PriceBadge,
        .ProductPrice-HighPrice,
        del {
            color: $white;
        }
    }
}

.primary-black {
    .pagebuilder-button-primary {
        background-color: $black;
        border-color: $black;
        color: $white;

        &:hover {
            background-color: #454545;
            border-color: #454545;
            color: $white;
        }
    }
}

h1 {
    &.evidence {
        font-size: 4.0rem;
        font-weight: 500;

        @include desktop-wide-1440 {
            font-size: 4.0rem; 
        }

        @include narrow-desktop {
            font-size: 3.6rem;
        }

        @include ultra-narrow-desktop {
            font-size: 3.4rem;
        }

        @include tablet {
            font-size: 3.2rem;
        }

        @include mobile {
            font-size: 3.2rem;
        }
    }

    &.light {
        font-weight: 300;
    }

    &.var-green {
        color: $default-primary-base-color;
        font-weight: bold;
        text-transform: none;
    }
}

h2 {
    &.evidence {
        font-size: 4rem;

        @include desktop-wide-1440 {
            font-size: 4.0rem; 
        }

        @include narrow-desktop {
            font-size: 3.6rem;
        }

        @include ultra-narrow-desktop {
            font-size: 3.4rem;
        }

        @include tablet {
            font-size: 3.2rem;
        }

        @include mobile {
            font-size: 3.2rem;
        }
    }

    &.text-up {
        text-transform: uppercase;
    }
}

h3 {
    a {
        font-family: $font-muli;
    }
}

.var-green {
    color: $default-primary-base-color;
}

.var-fux {
    color: $default-secondary-base-color;
}

.section-title {
    font-size: var(--h1-font-size);
    font-weight: var(--h1-font-weight);
    line-height: var(--h1-line-height);
    text-transform: var(--h1-text-transform);

    @include desktop-wide-1440 {
        font-size: var(--h1-font-size-wide);
        line-height: var(--h1-line-height-wide);
    }

    @include narrow-desktop {
        font-size: var(--h1-font-size-narrow);
        line-height: var(--h1-line-height-narrow);
    }

    @include tablet {
        font-size: var(--h1-font-size-tablet);
        line-height: var(--h1-line-height-tablet);
        margin-block-end: 10px;
    }

    @include mobile {
        font-size: var(--h1-font-size-mobile);
        padding-block-end: 6px;
        margin-block-end: 8px;
        margin-block-start: 0;
    }
}

.evidenza,
.evidenza h2 {
    font-family: $font-muli;
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;

    @include desktop-wide-1440 {
        font-size: 4.0rem; 
    }

    @include narrow-desktop {
        font-size: 3.6rem;
    }

    @include ultra-narrow-desktop {
        font-size: 3.4rem;
    }

    @include tablet {
        font-size: 3.2rem;
    }

    @include mobile {
        font-size: 3rem;
    }
}

.special {
    font-family: $font-muli;
    font-size: 3rem;
    font-weight: 500;
    line-height: 1.2;
    text-transform: none;

    @include desktop-wide-1440 {
        font-size: 3rem; 
    }

    @include narrow-desktop {
        font-size: 2.6rem;
    }

    @include ultra-narrow-desktop {
        font-size: 2.4rem;
    }

    @include tablet {
        font-size: 2.4rem;
    }

    @include mobile {
        font-size: 2.4rem;
    }

    &.extra {
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    &.big {
        font-size: 5.4rem;
        font-weight: 400;
        text-transform: uppercase;

        @include desktop-wide-1440 {
            font-size: 5rem; 
        }
    
        @include narrow-desktop {
            font-size: 4.8rem;
        }
    
        @include ultra-narrow-desktop {
            font-size: 4.5rem;
        }
    
        @include tablet {
            font-size: 4rem;
        }
    
        @include mobile {
            font-size: 3.8rem;
        }
    }
}

.txt-light {
    font-weight: 300;
    line-height: 1.6;
}

.no-pad {
    padding-inline: 0 !important;
}

.p24, .p24 p {
    font-size: 2.4rem;
    line-height: 1.4;
    font-weight: 300;

    @include desktop-wide-1440 {
        font-size: 2.1rem;
    }

    @include narrow-desktop {
        font-size: 2.1rem;
    }

    @include ultra-narrow-desktop {
        font-size: 1.8rem;
    }

    @include tablet {
        font-size: 1.8rem;
    }

    @include narrow-tablet {
        font-size: 1.6rem;
    }
}

.p18,
.p18 p {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.45;
}

.p16,
.p16 p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.55;
    color: #000000;
}

.p14, .p14 p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
}

.p15, .p15 p {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.6;
}

.p12, .p12 p {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.4;
}

.p10, .p10 p {
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 1px;
}

a {
    font-weight: 500;
}

.link-primary {
    color: $default-primary-base-color;
    font-weight: 500;

    &:hover {
        color: $default-neutral-base-color;
    }
}

.image-nofull,
.image-nofull img {
    width: auto;
    height: auto;
}

.mb-1 {
    margin-block-end: 10px !important;
}

.mb-2 {
    margin-block-end: 20px !important;
}

.mb-3 {
    margin-block-end: 30px !important;
}

.mb-4 {
    margin-block-end: 40px !important;
}

.mb-5 {
    margin-block-end: 50px !important;

    @include ultra-narrow-desktop {
        margin-block-end: 40px !important;
    }

    @include tablet {
        margin-block-end: 40px !important;
    }

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-6 {
    margin-block-end: 60px !important;

    @include ultra-narrow-desktop {
        margin-block-end: 50px !important;
    }

    @include tablet {
        margin-block-end: 50px !important;
    }

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-7 {
    margin-block-end: 70px !important;

    @include ultra-narrow-desktop {
        margin-block-end: 50px !important;
    }

    @include tablet {
        margin-block-end: 50px !important;
    }

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-8 {
    margin-block-end: 80px !important;

    @include ultra-narrow-desktop {
        margin-block-end: 60px !important;
    }

    @include tablet {
        margin-block-end: 60px !important;
    }
    
    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-9 {
    margin-block-end: 90px !important;

    @include desktop-wide-1600 {
        margin-block-end: 70px !important;
    }

    @include ultra-narrow-desktop {
        margin-block-end: 80px !important;
    }

    @include tablet {
        margin-block-end: 50px !important;
    }

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-10 {
    margin-block-end: 100px !important;

    @include desktop-wide-1600 {
        margin-block-end: 80px !important;
    }

    @include ultra-narrow-desktop {
        margin-block-end: 80px !important;
    }

    @include tablet {
        margin-block-end: 60px !important;
    }

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-11 {
    margin-block-end: 110px !important;

    @include desktop-wide-1600 {
        margin-block-end: 90px !important;
    }

    @include tablet {
        margin-block-end: 60px !important;
    }

    @include mobile {
        margin-block-end: 60px !important;
    }
}

.mb-14 {
    margin-block-end: 140px !important;

    @include desktop-wide-1600 {
        margin-block-end: 100px !important;
    }

    @include ultra-narrow-desktop {
        margin-block-end: 90px !important;
    }

    @include tablet {
        margin-block-end: 80px !important;
    }

    @include mobile {
        margin-block-end: 60px !important;
    }
}

.mb-15 {
    margin-block-end: 150px !important;

    @include desktop-wide-1600 {
        margin-block-end: 100px !important;
    }

    @include tablet {
        margin-block-end: 90px !important;
    }

    @include mobile {
        margin-block-end: 60px !important;
    }
}

.mb-16 {
    margin-block-end: 160px !important;

    @include desktop-wide-1600 {
        margin-block-end: 110px !important;
    }

    @include tablet {
        margin-block-end: 90px !important;
    }

    @include mobile {
        margin-block-end: 60px !important;
    }
}

.mb-20 {
    margin-block-end: 200px !important;

    @include desktop-wide-1440 {
        margin-block-end: 120px !important;
    }

    @include narrow-desktop {
        margin-block-end: 100px !important;
    }
    
    @include tablet {
        margin-block-end: 100px !important;
    }
}

.background-rosa {
    background-image: var(--background-rosa);
}

.background-azzurro {
    background-image: var(--background-azzurro);
}

.Label {
    height: auto;
    width: fit-content;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.3;
    font-weight: 800;
    text-transform: uppercase;
    font-family: $font-muli;
    background-color: $white;
    padding: 2px 5px;
    color: $default-neutral-base-color;

    @include mobile {
        font-size: 1rem;
        letter-spacing: 0.04rem;
        font-weight: 700;
    }

    &.Sale {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $default-neutral-base-color;
        border-top: none;
        background-color: $default-primary-base-color;
        width: 40px;
        height: 40px;
        padding: 0;
        font-weight: 800;

        span {
            font-size: 1.2rem;
            color: $default-neutral-base-color;

            @include mobile {
                font-size: 1rem;
                letter-spacing: 0.04rem;
                font-weight: 700;
            }
        }

        &.Bundle {
            width: fit-content;
            height: fit-content;
            padding: 10px;

            @include mobile {
                padding: 5px;
            }
        }
    }

    &.FastShipping {
        position: absolute;
        inset-block-end: 0px;
        width: fit-content;
        white-space: nowrap;
    }

    span {
        font-size: 1.2rem;
        color: $default-neutral-base-color;

        @include mobile {
            font-size: 1rem;
            letter-spacing: 0.04rem;
            font-weight: 700;
        }
    }
}

.Header {
    &-MinicartButtonWrapper span::before {
        content: url('images/icon-cart.svg');
        vertical-align: middle;
        margin-inline-end: 6px;
        
        @include tablet {
            transform: scale(0.75);
            display: inline-block;
            margin-inline-end: 2px;
        }
    }
}

.NotificationList {
    @include mobile {
        inset-block-start: 76px;
        z-index: 9999;
    }
}

.brand-slider {
    padding-block-start: 88px;

    @include ultra-narrow-desktop {
        padding-block-start: 50px;
    }

    @include narrow-tablet {
        padding-block-start: 50px;
    }

    @include mobile {
        padding-block-start: 19px;
        padding-block-end: 30px;
        padding-inline-start: 20px;
    }

    h3 {
        @include desktop {
            margin-block-end: 62px;
        }

        @include ultra-narrow-desktop {
            margin-block-end: 40px;
        }
    
        @include narrow-tablet {
            margin-block-end: 40px;
        }

        @include mobile {
            max-width: 265px;
            margin-block-start: 10px;
            margin-block-end: 11px;
            font-size: 1.2rem;
            line-height: 1.3;
            text-align: start !important;
        }
    }

    &::before {
        content: var(--arrow-down);
        display: block;
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 49.6%;

        @include mobile {
            display: none;
        }
    }
}

.product-showcase {
    @include mobile {
        padding-block-end: 40px;
        margin-block-end: 0 !important;
    }

    h2.section-title {
        text-align: start;
        letter-spacing: 4px;
        line-height: 1.2;
    }

    h4 {
        font-weight: 400;
        text-align: center;

        @include mobile {
            & + div {
                position: absolute;
                inset-block-end: -53px;
                width: calc(100% - 18px);
                text-align: center !important;
            }       
        }

        & + div {
            text-align: end;
        }
    }

    .ProductCard-Content {
        padding-block-start: 24px;

        @include ultra-narrow-desktop {
            padding-block-start: 17px;
        }

        @include narrow-tablet {
            padding-block-start: 17px;
        }

        @include mobile {
            padding-block-start: 12px;
            padding-block-end: 0;
        }
    }

    .ProductCard-MoreButtonWrapper {
        margin-block-start: 20px;
    }

    .pagebuilder-button-link {
        font-family: $font-muli;
        font-weight: 600;
        font-size: 1.2rem;
        text-transform: uppercase;
        padding-block-end: 4px;
        border-bottom: 1px solid $black;

        span {
            font-family: $font-muli;
        }
    }

    .container {
        &.product-carousel {
            @include mobile {
                padding-inline: 0;
                width: 207%;
                max-width: unset;

                .slick-next,
                .slick-prev {
                    display: none !important;
                }

                .slick-slide {
                    padding-inline-end: 5px;
                }
            }

            + p {
                @include mobile {
                    margin-block-end: 0;
                }
            }
        }
    }

    .Label {
        span {
            @include mobile {
                font-size: 1.2rem;
            }
        }
    }
}

.box-genere {
    @include desktop {
        padding-block-end: 106px;
        padding-block-start: 70px;
    }

    @include ultra-narrow-desktop {
        padding-block-end: 70px;
        padding-block-start: 40px;
    }

    @include narrow-tablet {
        padding-block-end: 70px;
        padding-block-start: 40px;
    }

    @include mobile {
        padding-block-start: 20px;
        padding-block-end: 20px;
        padding-inline-start: 19px;
    }

    .pagebuilder-column-group {
        display: flex;
        gap: 40px;

        @include mobile {
            flex-direction: column;
            gap: 20px;
        }

        .pagebuilder-column {
            @include mobile {
                min-width: 100% !important;
            }

            &:hover {
                img {
                    opacity: 0.8;
                }
            }
        }

        h2 {
            margin-block: 18px 23px;
            letter-spacing: 3px;

            @include mobile {
                margin-block: 4px 10px;
                font-size: 1.4rem;
                letter-spacing: 0.084rem;
            }
        }

        .Button-Text {
            @include mobile {
                display: flex !important;
            }

            .pagebuilder-button-primary,
            .pagebuilder-button-secondary {
                @include mobile {
                    min-width: unset;
                    max-width: fit-content;
                }
            }
        }
    }
}

.best-sellers {
    .ProductListWidget {
        padding-block-end: 0;
    }

    .best-seller-box {
        padding-block-start: 25px;
        padding-inline-end: 10px;
    }    
}

.banner-promo-wrapper {
    padding-inline: 0 !important;
    width: 100% !important;
    margin-block-end: 0 !important;
}

.banner-vantaggi {
    background-color: #ffffff;
    color: $black;
    margin: 0 auto;

    @include mobile {
        margin-block-start: 0;
        max-width: 100vw;
    }

    .pagebuilder-column-group {
        width: var(--content-wrapper-large);
        margin: 0 auto;
        padding-block: 80px;
        border-top: 1px solid #F0F0F0;

        @include ultra-narrow-desktop {
            padding-block: 50px;
        }

        @include tablet {
            padding-block: 50px;
        }

        @include narrow-tablet {
            padding-block: 40px;
        }

        @include mobile {
            padding-block: 20px;
            flex-direction: column;
        }
    }

    .pagebuilder-column {
        justify-content: center;

        @include mobile {
            width: 100% !important;
        }
    }

    h3 {
        margin-block: 0;
        color: $black;

        @include mobile {
            font-size: 1rem;
            display: flex;
            align-items: center;
            line-height: 2.9;
            font-weight: 700;
        }

        &::before {
            content: var(--icon-check);
            margin-inline-end: 7px;
        }
    }

    img {
        display: inline-block;
        vertical-align: middle;
        margin-inline-end: 10px;
        margin-block-end: 10px;

        @include ultra-narrow-desktop {
            transform: scale(0.9);
        }

        @include tablet {
            transform: scale(0.8);
        }

        @include mobile {
            transform: scale(0.6);
            display: block;
            margin-inline-end: 0;
            margin-inline-start: 0;
            margin-block-end: 0;
        }
    }
}
